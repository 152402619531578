import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bcc44c60 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _5ee299fe = () => interopDefault(import('../pages/account/absence.vue' /* webpackChunkName: "pages/account/absence" */))
const _3585228e = () => interopDefault(import('../pages/account/documents.vue' /* webpackChunkName: "pages/account/documents" */))
const _76234e01 = () => interopDefault(import('../pages/account/journey.vue' /* webpackChunkName: "pages/account/journey" */))
const _7ea38fc4 = () => interopDefault(import('../pages/account/journey/index.vue' /* webpackChunkName: "pages/account/journey/index" */))
const _069451e6 = () => interopDefault(import('../pages/account/journey/exercises.vue' /* webpackChunkName: "pages/account/journey/exercises" */))
const _7a8c0b6a = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _37ef94da = () => interopDefault(import('../pages/account/project.vue' /* webpackChunkName: "pages/account/project" */))
const _9f07629c = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _17298d43 = () => interopDefault(import('../pages/activation.vue' /* webpackChunkName: "pages/activation" */))
const _5b5fc39c = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _3c830245 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _9ed7fdf0 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _ed6953bc = () => interopDefault(import('../pages/chat/archived-conversations/index.vue' /* webpackChunkName: "pages/chat/archived-conversations/index" */))
const _65e2cdb3 = () => interopDefault(import('../pages/chat/bookmarks.vue' /* webpackChunkName: "pages/chat/bookmarks" */))
const _00b92cee = () => interopDefault(import('../pages/chat/conversations/index.vue' /* webpackChunkName: "pages/chat/conversations/index" */))
const _30accca8 = () => interopDefault(import('../pages/chat/drafts.vue' /* webpackChunkName: "pages/chat/drafts" */))
const _dbe327be = () => interopDefault(import('../pages/chat/notifications/index.vue' /* webpackChunkName: "pages/chat/notifications/index" */))
const _466b6832 = () => interopDefault(import('../pages/chat/templates/index.vue' /* webpackChunkName: "pages/chat/templates/index" */))
const _48c0c870 = () => interopDefault(import('../pages/chat/peer-conversations/new.vue' /* webpackChunkName: "pages/chat/peer-conversations/new" */))
const _738f92bc = () => interopDefault(import('../pages/chat/templates/new/_messageId.vue' /* webpackChunkName: "pages/chat/templates/new/_messageId" */))
const _06e03a31 = () => interopDefault(import('../pages/chat/conversations/_id.vue' /* webpackChunkName: "pages/chat/conversations/_id" */))
const _2cdb02c9 = () => interopDefault(import('../pages/chat/notifications/_id.vue' /* webpackChunkName: "pages/chat/notifications/_id" */))
const _350af38a = () => interopDefault(import('../pages/chat/peer-conversations/_id.vue' /* webpackChunkName: "pages/chat/peer-conversations/_id" */))
const _c48c61cc = () => interopDefault(import('../pages/chat/templates/_id.vue' /* webpackChunkName: "pages/chat/templates/_id" */))
const _4d86cd97 = () => interopDefault(import('../pages/chat/unread-conversations/_id.vue' /* webpackChunkName: "pages/chat/unread-conversations/_id" */))
const _fb5c6f62 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _06b49792 = () => interopDefault(import('../pages/community/index/index.vue' /* webpackChunkName: "pages/community/index/index" */))
const _62e0e91c = () => interopDefault(import('../pages/community/index/map.vue' /* webpackChunkName: "pages/community/index/map" */))
const _3a09a7c4 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _0ac01012 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _73d7aea4 = () => interopDefault(import('../pages/edition/index.vue' /* webpackChunkName: "pages/edition/index" */))
const _e11ab068 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a8194b92 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _da61ecf2 = () => interopDefault(import('../pages/recover.vue' /* webpackChunkName: "pages/recover" */))
const _263101ed = () => interopDefault(import('../pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _72e7277e = () => interopDefault(import('../pages/student-directory/index.vue' /* webpackChunkName: "pages/student-directory/index" */))
const _5a6844b4 = () => interopDefault(import('../pages/token-expired.vue' /* webpackChunkName: "pages/token-expired" */))
const _683e7750 = () => interopDefault(import('../pages/webinar/index.vue' /* webpackChunkName: "pages/webinar/index" */))
const _630841d3 = () => interopDefault(import('../pages/webinar/index/index.vue' /* webpackChunkName: "pages/webinar/index/index" */))
const _3d2dee76 = () => interopDefault(import('../pages/webinar/index/bookmark.vue' /* webpackChunkName: "pages/webinar/index/bookmark" */))
const _ac825c12 = () => interopDefault(import('../pages/webinar/index/search.vue' /* webpackChunkName: "pages/webinar/index/search" */))
const _36cd4df4 = () => interopDefault(import('../pages/community/rules.vue' /* webpackChunkName: "pages/community/rules" */))
const _830c59ce = () => interopDefault(import('../pages/courses/empty.vue' /* webpackChunkName: "pages/courses/empty" */))
const _28b2dd12 = () => interopDefault(import('../pages/courses/free/index.ts' /* webpackChunkName: "pages/courses/free/index" */))
const _3bd89d66 = () => interopDefault(import('../pages/edition/hot-seat/index.vue' /* webpackChunkName: "pages/edition/hot-seat/index" */))
const _3763e7f4 = () => interopDefault(import('../pages/onboarding/waiting.vue' /* webpackChunkName: "pages/onboarding/waiting" */))
const _48eee07a = () => interopDefault(import('../pages/community/profile/edit.vue' /* webpackChunkName: "pages/community/profile/edit" */))
const _3b52a9de = () => interopDefault(import('../pages/community/profile/_id.vue' /* webpackChunkName: "pages/community/profile/_id" */))
const _bace6eb8 = () => interopDefault(import('../pages/courses/free/_masterclassId/index.vue' /* webpackChunkName: "pages/courses/free/_masterclassId/index" */))
const _f0ca7d64 = () => interopDefault(import('../pages/edition/hot-seat/_id.vue' /* webpackChunkName: "pages/edition/hot-seat/_id" */))
const _46238f29 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/index" */))
const _1112a792 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/index" */))
const _79d5f9e6 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/lessons/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/lessons/index" */))
const _5e3cc97f = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/lessons/_lessonId.vue' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/lessons/_lessonId" */))
const _25fcc3f4 = () => interopDefault(import('../pages/courses/_id.vue' /* webpackChunkName: "pages/courses/_id" */))
const _5bf5efee = () => interopDefault(import('../pages/courses/_id/index.vue' /* webpackChunkName: "pages/courses/_id/index" */))
const _f8fa3da2 = () => interopDefault(import('../pages/courses/_id/404.vue' /* webpackChunkName: "pages/courses/_id/404" */))
const _4bba1e1e = () => interopDefault(import('../pages/courses/_id/certification.vue' /* webpackChunkName: "pages/courses/_id/certification" */))
const _f3d87058 = () => interopDefault(import('../pages/courses/_id/lesson/index.vue' /* webpackChunkName: "pages/courses/_id/lesson/index" */))
const _05b89f6a = () => interopDefault(import('../pages/courses/_id/milestone/index.vue' /* webpackChunkName: "pages/courses/_id/milestone/index" */))
const _2bfd8c1e = () => interopDefault(import('../pages/courses/_id/lesson/_lesson_id.vue' /* webpackChunkName: "pages/courses/_id/lesson/_lesson_id" */))
const _bd84017e = () => interopDefault(import('../pages/courses/_id/milestone/_milestone_id.vue' /* webpackChunkName: "pages/courses/_id/milestone/_milestone_id" */))
const _bbedebe8 = () => interopDefault(import('../pages/edition/_id.vue' /* webpackChunkName: "pages/edition/_id" */))
const _779e50e2 = () => interopDefault(import('../pages/edition/_id/index.vue' /* webpackChunkName: "pages/edition/_id/index" */))
const _692b2030 = () => interopDefault(import('../pages/edition/_id/planning.vue' /* webpackChunkName: "pages/edition/_id/planning" */))
const _aba45c2e = () => interopDefault(import('../pages/edition/_id/replays.vue' /* webpackChunkName: "pages/edition/_id/replays" */))
const _7c877276 = () => interopDefault(import('../pages/follow-up/_id.vue' /* webpackChunkName: "pages/follow-up/_id" */))
const _498c0ce7 = () => interopDefault(import('../pages/onboarding/_page.vue' /* webpackChunkName: "pages/onboarding/_page" */))
const _5b1c2a88 = () => interopDefault(import('../pages/planning/_date.vue' /* webpackChunkName: "pages/planning/_date" */))
const _15a9bc46 = () => interopDefault(import('../pages/signature/_id.vue' /* webpackChunkName: "pages/signature/_id" */))
const _4ebbe490 = () => interopDefault(import('../pages/webinar/_id.vue' /* webpackChunkName: "pages/webinar/_id" */))
const _7469f696 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _bcc44c60,
    name: "account",
    children: [{
      path: "absence",
      component: _5ee299fe,
      name: "account-absence"
    }, {
      path: "documents",
      component: _3585228e,
      name: "account-documents"
    }, {
      path: "journey",
      component: _76234e01,
      children: [{
        path: "",
        component: _7ea38fc4,
        name: "account-journey"
      }, {
        path: "exercises",
        component: _069451e6,
        name: "account-journey-exercises"
      }]
    }, {
      path: "profile",
      component: _7a8c0b6a,
      name: "account-profile"
    }, {
      path: "project",
      component: _37ef94da,
      name: "account-project"
    }, {
      path: "settings",
      component: _9f07629c,
      name: "account-settings"
    }]
  }, {
    path: "/activation",
    component: _17298d43,
    name: "activation"
  }, {
    path: "/callback",
    component: _5b5fc39c,
    name: "callback"
  }, {
    path: "/chat",
    component: _3c830245,
    children: [{
      path: "",
      component: _9ed7fdf0,
      name: "chat"
    }, {
      path: "archived-conversations",
      component: _ed6953bc,
      name: "chat-archived-conversations"
    }, {
      path: "bookmarks",
      component: _65e2cdb3,
      name: "chat-bookmarks"
    }, {
      path: "conversations",
      component: _00b92cee,
      name: "chat-conversations"
    }, {
      path: "drafts",
      component: _30accca8,
      name: "chat-drafts"
    }, {
      path: "notifications",
      component: _dbe327be,
      name: "chat-notifications"
    }, {
      path: "templates",
      component: _466b6832,
      name: "chat-templates"
    }, {
      path: "peer-conversations/new",
      component: _48c0c870,
      name: "chat-peer-conversations-new"
    }, {
      path: "templates/new/:messageId?",
      component: _738f92bc,
      name: "chat-templates-new-messageId"
    }, {
      path: "conversations/:id",
      component: _06e03a31,
      name: "chat-conversations-id"
    }, {
      path: "notifications/:id",
      component: _2cdb02c9,
      name: "chat-notifications-id"
    }, {
      path: "peer-conversations/:id?",
      component: _350af38a,
      name: "chat-peer-conversations-id"
    }, {
      path: "templates/:id",
      component: _c48c61cc,
      name: "chat-templates-id"
    }, {
      path: "unread-conversations/:id?",
      component: _4d86cd97,
      name: "chat-unread-conversations-id"
    }]
  }, {
    path: "/community",
    component: _fb5c6f62,
    children: [{
      path: "",
      component: _06b49792,
      name: "community-index"
    }, {
      path: "map",
      component: _62e0e91c,
      name: "community-index-map"
    }]
  }, {
    path: "/courses",
    component: _3a09a7c4,
    name: "courses"
  }, {
    path: "/dashboard",
    component: _0ac01012,
    name: "dashboard"
  }, {
    path: "/edition",
    component: _73d7aea4,
    name: "edition"
  }, {
    path: "/login",
    component: _e11ab068,
    name: "login"
  }, {
    path: "/logout",
    component: _a8194b92,
    name: "logout"
  }, {
    path: "/recover",
    component: _da61ecf2,
    name: "recover"
  }, {
    path: "/resetPassword",
    component: _263101ed,
    name: "resetPassword"
  }, {
    path: "/student-directory",
    component: _72e7277e,
    name: "student-directory"
  }, {
    path: "/token-expired",
    component: _5a6844b4,
    name: "token-expired"
  }, {
    path: "/webinar",
    component: _683e7750,
    children: [{
      path: "",
      component: _630841d3,
      name: "webinar-index"
    }, {
      path: "bookmark",
      component: _3d2dee76,
      name: "webinar-index-bookmark"
    }, {
      path: "search",
      component: _ac825c12,
      name: "webinar-index-search"
    }]
  }, {
    path: "/community/rules",
    component: _36cd4df4,
    name: "community-rules"
  }, {
    path: "/courses/empty",
    component: _830c59ce,
    name: "courses-empty"
  }, {
    path: "/courses/free",
    component: _28b2dd12,
    name: "courses-free"
  }, {
    path: "/edition/hot-seat",
    component: _3bd89d66,
    name: "edition-hot-seat"
  }, {
    path: "/onboarding/waiting",
    component: _3763e7f4,
    name: "onboarding-waiting"
  }, {
    path: "/community/profile/edit",
    component: _48eee07a,
    name: "community-profile-edit"
  }, {
    path: "/community/profile/:id?",
    component: _3b52a9de,
    name: "community-profile-id"
  }, {
    path: "/courses/free/:masterclassId",
    component: _bace6eb8,
    name: "courses-free-masterclassId"
  }, {
    path: "/edition/hot-seat/:id?",
    component: _f0ca7d64,
    name: "edition-hot-seat-id"
  }, {
    path: "/courses/free/:masterclassId/modules",
    component: _46238f29,
    name: "courses-free-masterclassId-modules"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId",
    component: _1112a792,
    name: "courses-free-masterclassId-modules-moduleId"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId/lessons",
    component: _79d5f9e6,
    name: "courses-free-masterclassId-modules-moduleId-lessons"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId/lessons/:lessonId",
    component: _5e3cc97f,
    name: "courses-free-masterclassId-modules-moduleId-lessons-lessonId"
  }, {
    path: "/courses/:id",
    component: _25fcc3f4,
    children: [{
      path: "",
      component: _5bf5efee,
      name: "courses-id"
    }, {
      path: "404",
      component: _f8fa3da2,
      name: "courses-id-404"
    }, {
      path: "certification",
      component: _4bba1e1e,
      name: "courses-id-certification"
    }, {
      path: "lesson",
      component: _f3d87058,
      name: "courses-id-lesson"
    }, {
      path: "milestone",
      component: _05b89f6a,
      name: "courses-id-milestone"
    }, {
      path: "lesson/:lesson_id",
      component: _2bfd8c1e,
      name: "courses-id-lesson-lesson_id"
    }, {
      path: "milestone/:milestone_id",
      component: _bd84017e,
      name: "courses-id-milestone-milestone_id"
    }]
  }, {
    path: "/edition/:id",
    component: _bbedebe8,
    children: [{
      path: "",
      component: _779e50e2,
      name: "edition-id"
    }, {
      path: "planning",
      component: _692b2030,
      name: "edition-id-planning"
    }, {
      path: "replays",
      component: _aba45c2e,
      name: "edition-id-replays"
    }]
  }, {
    path: "/follow-up/:id?",
    component: _7c877276,
    name: "follow-up-id"
  }, {
    path: "/onboarding/:page?",
    component: _498c0ce7,
    name: "onboarding-page"
  }, {
    path: "/planning/:date?",
    component: _5b1c2a88,
    name: "planning-date"
  }, {
    path: "/signature/:id?",
    component: _15a9bc46,
    name: "signature-id"
  }, {
    path: "/webinar/:id",
    component: _4ebbe490,
    name: "webinar-id"
  }, {
    path: "/",
    component: _7469f696,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
