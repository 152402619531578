











import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { MkrContainedButton } from '@livementor/mikado_reborn/src/index-core'

const usersModule = namespace('users')

@Component({
  components: {
    MkrContainedButton,
  },
})
export default class HelpButton extends Vue {
  @usersModule.Getter('currentUserIsMentor')
  currentUserIsMentor!: boolean

  helpClicked() {
    const mentorUrl =
      'https://livementor.zendesk.com/hc/fr/categories/15195787533596--Je-suis-un-mentor'
    const studentUrl = 'https://livementor.zendesk.com/hc/fr'
    const url = this.currentUserIsMentor ? mentorUrl : studentUrl

    this.$segment.track('Clicked Help', { tracking_version: '1.0', path: this.$route.path })
    window.open(url, '_blank')
  }
}
